/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAypp4iVu01wxIG-1z0y_-FYlhV4kcWk5c",
  "appId": "1:372607175727:web:45d939953d08af886c0ada",
  "authDomain": "schooldog-instance-mec.firebaseapp.com",
  "measurementId": "G-V6XV8V76Z7",
  "messagingSenderId": "372607175727",
  "project": "schooldog-instance-mec",
  "projectId": "schooldog-instance-mec",
  "showGaBranding": true,
  "storageBucket": "schooldog-instance-mec.appspot.com"
}
